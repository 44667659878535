.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #bbb;
}

.logo {
  padding: 2px 0px;
  font-weight: 500;
  color: #444;
}

.navbar {
  border-bottom: 0.4px solid #aaa;
}

.dropdown-menu {
  background: #f2f2f2;
}
.dropdown-item {
  line-height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.dropdown-item:hover {
  background: #ccc;
}
.dropdown-item img {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
.nav-link img {
  width: 30px;
  height: 20px;
  margin-right: 5px;
}

.languages {
  border: 0.5px solid #444;
  border-radius: 10px;
}

.telebike .each-slide img {
  cursor: pointer;
}

.telebike .react-slideshow-container .nav {
  width: 50px;
  height: 50px;
}

.telebike .react-slideshow-container .nav:first-child {
  margin-right: -50px;
}
.telebike .react-slideshow-container .nav:last-child {
  margin-left: -50px;
}

.telebike .itemDivContainer {
  padding: 30px 15px;
  display: inline-block;
}

.telebike .itemDivContainer > * {
  display: inline-block;
}

.telebike .moreInfoDiv {
  padding-left: 10%;
  padding-top: 50px;
}

.telebike .moreInfoDiv button {
  border-radius: 30px;
  margin-top: 30px;
}

.telebike .react-slideshow-container .nav span {
  width: 20px;
  height: 20px;
}

.telebike .react-slideshow-container .slide {
  width: 100%;
}

.productsUl {
  list-style: none;
  padding: 0px;
  color: #fff;
  margin: 0px;
  line-height: 38px;
}

.sortBySelect {
  border: 0.2px solid #aaa;
  padding: 2px 30px 2px 10px;
}

.telebike .slide-container {
  padding: 0px 0%;
}

@media only screen and (max-width: 1200px) {
  .telebike .slide-container {
    padding: 0px 0%;
  }
}

@media only screen and (max-width: 700px) {
  .telebike .slide-container {
    padding: 0px 0%;
  }
}

@media only screen and (max-width: 1100px) {
  h4 {
    font-size: 21px;
  }
}
@media only screen and (max-width: 600px) {
  .nav-link img {
    width: 40px;
    height: 27px;
    margin-right: 5px;
  }
}

.btn-sm.btn-default:focus {
  outline: none !important;
  box-shadow: none;
}
