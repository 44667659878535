.telebike .navbar .nav-link {
    font-weight: 400;
    color: #fff;
}

.telebike .bg-tele .active {
    font-weight: 500;
    color: #ff6600;
}

.telebike .navbar .nav-link i {
    display: none;
}
