.telebike .input-group-search {
    background-color: #fff;
    color: #aaa;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 20px;
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.telebike .input-group > * {
    line-height: 30px;
    height: 34px;
}

.telebike .searchBar {
    border-left: 0px;
}

.telebike .searchBar:focus {
    outline: none !important;
    box-shadow: none;
    border-color: #ccc;
}
