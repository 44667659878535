.bg-tele {
    background-color: #3e5062;
}

.telebike .bg-tele .container-fluid {
    max-width: 100%;
}

.bg-tele .logo > img {
    margin: 2px 5px 2px -5px;
    width: 170px;
    margin-top: 5px;
}

.bg-tele ul {
    min-width: 580px;
    display: inline-block;
}

.bg-tele ul > * {
    display: inline-block;
    width: auto;
}

.bg-tele .input-group {
    /* width: 100%; */
    min-width: 300px;
    margin-right: 15px;
}

.telebike .topMargin {
    margin-top: 30px;
    display: inline-block;
}

.bg-tele .bg-success {
    background-color: #ff6600 !important;
    color: #fff !important;
}

.bg-tele .languages {
    font-size: 18px;
    background-color: #ff6600;
    color: #fff;
    border: 1px solid #333;
    border-radius: 10px;
}

.bg-tele .cartIconDiv {
    padding: 4px 12px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #bbb;
}
.bg-tele .cartIconDiv {
    padding: 8px 7px;
    border: 1px solid #333;
    border-radius: 10px;
    font-size: 15px;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    background: #28a745;
}

.cartIconDiv i {
    margin-right: 8px;
}

.cartIconDiv span {
}

@media only screen and (max-width: 1200px) {
    .telebike .logo > img {
        /* width: 140px; */
    }
    .telebike .input-group {
        min-width: 300px;
    }
    .bg-tele ul {
        min-width: 300px;
        width: auto;
        max-width: 580px;
    }
}

@media only screen and (max-width: 760px) {
    .bg-tele ul {
        display: inline-block;
        width: 100%;
    }
    .bg-tele ul > * {
        min-width: 10px;
        margin-right: 20px;
        display: inline-block;
        margin: 5px 0.5% 5px;
    }
    .bg-tele ul > .cartIconDiv {
        min-width: 48.5%;
    }
    .telebike .navbar-toggler-icon {
        margin-right: -10px;
    }
    .telebike .navbar-toggler-icon i {
        color: #fff;
        font-size: 30px;
        margin-top: -1px;
    }
    .telebike .navbar-toggler:hover {
        outline: none !important;
        box-shadow: none;
    }

    .telebike .navbar-nav .nav-link {
        font-size: 18px;
        padding-right: 10px;
    }
    .bg-tele .input-group {
        width: 100%;
        margin: 7px auto 3px;
        margin-right: 0px;
    }
    .telebike .navbar {
        position: relative;
    }

    .telebike .logo {
        margin-right: 3px;
    }

    .telebike .logo > img {
        width: 180px;
    }
    .bg-tele ul {
        min-width: 100px;
    }
    .telebike .navbar-nav .nav-link {
        font-size: 16px;
        padding-right: 5px;
    }

    .bg-tele .cartIconDiv {
        margin-right: 1%;
    }
}
