.main-footer {
  width: 100%;
  padding: 15px 0px;
  vertical-align: middle;
  /* margin-top: 20px; */
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0.4px solid #aaa;
  bottom: 0px;
  z-index: 3;
}

.main-footer a {
  color: #ff6600;
}

html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 55px;
}
