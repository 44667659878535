.side-drawer {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 55%;
    background: #e3e3e3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.side-drawer-left {
    left: 0;
    top: 0;
}

.side-drawer-right {
    right: 0;
    top: 0;
}
